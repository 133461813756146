import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom, last, map, Observable, tap } from 'rxjs';
import { Order, OrderStatusEnum, Product, ProductLineItem, UpdateProductLineItemDto } from 'src/app/api-client';
import { Option } from '../../../atoms/custom-select/custom-select.component';
import { OrderService } from '../../../services/order.service';
import { ProductService } from '../../../services/product.service';
import { TemplateService } from '../../../services/template.service';
import { ToastService } from '../../../services/toast.service';
import { colors } from '.././colors';

export interface updateSlot {
  slot: string;
  opt: Option;
  checked: boolean;
  side: string | undefined;
  custom: string | undefined;
  amount: number;
  amount2: number;
  amount3: number;
}

@Component({
  selector: 'app-order-field',
  templateUrl: './order-field.component.html',
  styleUrls: ['./order-field.component.scss'],
  animations: [
    trigger('addedElement', [
      state('*', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state('added', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state(
        'void',
        style({
          height: '0px',
          opacity: 0,
          paddingTop: 0,
          paddingBottom: 0,
          visibility: 'visible'
        })
      ),
      transition('void => added', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('void <=> *', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('* => void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('* => *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class OrderFieldComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  index!: number;

  @Input()
  dividerWallIndices$!: Observable<number[]>;

  addModalOpen = false;
  colorPickerOpen = false;

  highlightOverride = false;
  highlightLocks = false;

  fieldAmount$!: Observable<number | undefined>;

  isExpanded: boolean = false;

  OrderStatusEnum = OrderStatusEnum;

  readonly RAL_COLORS = colors;
  selectedColor!: {
    code: string;
    rgb_approx: string;
    rgb_hex: string;
    name: string;
  };

  ralColor!: Observable<Option>;
  ral7035!: Observable<Option>;

  ralError: string = '';

  selectedSocketHeightOption!: Option;
  socketHeightOptions: Option[] = [
    {
      value: '50',
      description: 'mm',
      meta: '',
      label: '50'
    },
    {
      value: '100',
      description: 'mm',
      meta: '',
      label: '100'
    },
    {
      value: '200',
      description: 'mm',
      meta: '',
      label: '200'
    }
  ];

  slot13SelectedOption!: Option;
  slot13Options: Option[] = [
    {
      value: '',
      description: '×',
      meta: '',
      label: '0'
    },
    {
      value: '2',
      description: '×',
      meta: '',
      label: '2'
    },
    {
      value: '4',
      description: '×',
      meta: '',
      label: '4'
    }
  ];

  sideOptions!: Option[];

  materialOptions!: Option[];

  materialOptionsFloor!: Option[];

  lights = ['slled50t', 'slled50ir', 'slled400', 'slled600', 'slled900'];

  selectedProtection!: Option;
  protectionLevelOptions: Option[] = [
    {
      value: '00',
      description: '',
      meta: '',
      label: 'IP00'
    },
    {
      value: '20',
      description: '',
      meta: '',
      label: 'IP20'
    },
    {
      value: '40',
      description: '',
      meta: '',
      label: 'IP40'
    },
    {
      value: '54',
      description: '',
      meta: '',
      label: 'IP54'
    }
  ];

  labelPositionOptions!: Option[];

  fontOptions: Option[] = [
    {
      value: 'Arial',
      description: '',
      meta: '',
      label: 'Arial'
    },
    {
      value: 'Calib',
      description: '',
      meta: '',
      label: 'Calibri'
    },
    {
      value: 'Helv',
      description: '',
      meta: '',
      label: 'Helvetica'
    },
    {
      value: 'NT Rom',
      description: '',
      meta: '',
      label: 'New Times Roman'
    }
  ];

  fontSizeOptions: Option[] = [
    '10',
    '15',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
    '60',
    '65',
    '70',
    '75',
    '80',
    '85',
    '90',
    '95',
    '100'
  ].map((size) => ({
    value: size,
    description: '',
    meta: '',
    label: size
  }));

  labelPosition2Options!: Option[];

  colorOptions!: Option[];

  labelActive: boolean = false;
  labelProduct?: ProductLineItem;
  font: Option = this.fontOptions[0];
  fontSize: Option = this.fontSizeOptions[3];
  color!: Option;
  labelPosition!: Option;
  labelPosition2!: Option;

  wallSelectedSide!: Option;

  emptySlot5!: Option;
  emptySlot6!: Option;

  defaultSlot5!: Option | undefined;
  defaultSlot6!: Option | undefined;

  slotOptions: Observable<Option[]>[] = [];
  selectedSlots: Option[] = [];

  slot8active!: boolean;

  slot9amount!: number;
  slot9amount2!: number;
  slot9amount3!: number;

  slot10active!: boolean;
  slot10SelectedSide!: Option;

  slot11amount: number = 1;
  slot11active!: boolean;
  slot11SelectedSide!: Option;

  slot12All = new BehaviorSubject<Product[]>([]);

  slot12amount: number = 1;
  slot12active!: boolean;
  slot12SelectedSide!: Option;

  slot13amount: number = 0;

  slot15active!: boolean;
  slot15SelectedCustom!: Option;

  slot20active!: boolean;
  slot20SelectedCustom!: Option;

  slot22active!: boolean;

  slot23active!: boolean;

  // WALL
  wallFlat = false;
  wallAmountSelected!: Option;
  wallAmountOptions: Option[] = [
    {
      value: '1',
      description: '×',
      label: '1'
    },
    {
      value: '2',
      description: '×',
      label: '2'
    }
  ];

  customSocketDivision: number[] = [];
  customSocketDivisionWarning = '';

  createTemplateModal = false;
  templateName = '';

  addedState = '*';

  doubleDoorSelected = false;

  @ViewChild('elem') elem!: ElementRef;
  @ViewChild('elem2') elem2!: ElementRef;
  constructor(
    public orderService: OrderService,
    public productService: ProductService,
    public templateService: TemplateService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    /**
     * This events get called by all clicks on the page
     */
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.elem || !this.elem2) return;
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (!this.elem.nativeElement.contains(e.target) && !this.elem2.nativeElement.contains(e.target)) {
        this.colorPickerOpen = false;
        this.cdr.detectChanges();
      }
    });

    this.initSides();
    this.initMaterials();
    this.initLabels();

    this.color = this.colorOptions[0];
    this.labelPosition = this.labelPositionOptions[0];
    this.labelPosition2 = this.labelPosition2Options[0];

    this.fieldAmount$ = this.orderService.order$.pipe(
      map((o) => o?.lineItems.reduce((sum, curr) => (sum += curr.isField ? 1 : 0), 0))
    );

    this.translate.onLangChange.subscribe((change) => {
      this.initLabels();
      this.initSides();
      this.initMaterials();
      this.initActiveFields();
      this.initSlots();
    });
  }

  async updateLabel(
    label?: string,
    font?: string,
    fontSize?: string,
    position?: string,
    position2?: string,
    color?: string
  ) {
    position = this.translate.instant(`Configuration.Label.Pos1.${position}`);
    position2 = this.translate.instant(`Configuration.Label.Pos2.${position2}`);
    color = this.translate.instant(`Configuration.Label.Colors.${color}`);

    this.font = this.fontOptions.find((opt) => opt.value == font) ?? this.font;
    this.fontSize = this.fontSizeOptions.find((opt) => opt.value == fontSize) ?? this.fontSize;
    this.color = this.colorOptions.find((opt) => opt.label == color) ?? this.color;
    this.labelPosition = this.labelPositionOptions.find((opt) => opt.label == position) ?? this.labelPosition;
    this.labelPosition2 = this.labelPosition2Options.find((opt) => opt.label == position2) ?? this.labelPosition2;

    const updateData: UpdateProductLineItemDto = {
      custom: label,
      additionalDescription: `${font}, ${fontSize} mm, ${position}, ${position2}, ${color}`,
      additionalDescriptionFr: `${font}, ${fontSize} mm, ${position}, ${position2}, ${color}`
    };

    if (!this.labelProduct) {
      if (!label) return;
      const labelProduct = (await this.productService.api.productControllerGetLabelProduct(this.item.type)).data;
      this.orderService.addLineItem({
        productId: labelProduct.id,
        addedFrom: [this.item.id],
        ...updateData
      });
    } else {
      if (!label) {
        this.orderService.removeLineItem(this.labelProduct.id);
        return;
      }

      this.orderService.updateLineItem(this.labelProduct.id, updateData);
    }
  }

  async copyLineItemIdToClipboard(id: string) {
    try {
      this.orderService.clipboard$.next(id);
      await navigator.clipboard.writeText(id);
      this.toastService.displayToast({ message: 'Feld in Zwischenablage kopiert', type: 'success', time: 5000 });
    } catch (e) {
      console.error(e);
      this.toastService.displayToast({ message: 'Konnte Feld nicht kopieren', type: 'danger', time: 15000 });
    }
  }

  setDefaultSocketDivision(order: Order) {
    this.customSocketDivision = order.lineItems.filter((l) => l.isField).map((l) => l.width as number);
    this.updateSocketDivision();
  }

  updateSocketDivision() {
    this.updateCustom(this.customSocketDivision.join(', '));
    this.checkCustomSocketDivisionWarning();
  }

  async checkCustomSocketDivisionWarning() {
    this.customSocketDivisionWarning = '';
    const sum = this.customSocketDivision.map((c) => +c).reduce((p, c) => (c += p), 0);

    if (sum == 0) return;

    if (this.item.width && sum > this.item.width) {
      this.customSocketDivisionWarning = await firstValueFrom(
        this.translate.get('Configuration.Fields.DivisonWarningLarger')
      );
    }

    if (this.item.width && sum < this.item.width) {
      this.customSocketDivisionWarning = await firstValueFrom(
        this.translate.get('Configuration.Fields.DivisonWarningSmaller')
      );
    }
  }

  toggleIsExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  addField() {
    this.orderService.addLineItem({
      productId: this.item.productId,
      sorting: this.item.sorting + 1
    });
    this.orderService.addedSorting = this.item.sorting + 10;
  }

  async pasteField() {
    try {
      const id = this.orderService.clipboard$.value;

      if (id == undefined || id.length !== 36) {
        this.toastService.displayToast({
          message: await firstValueFrom(this.translate.get('Configuration.Fields.NoFieldCopied')),
          type: 'danger',
          time: 15000
        });
        return;
      }
      this.orderService.pasteLineItem(id, (this.item.sorting + 1).toString());
      this.orderService.addedSorting = this.item.sorting + 10;
    } catch (e) {
      console.error(e);
      this.toastService.displayToast({
        message: await firstValueFrom(this.translate.get('Configuration.Fields.FieldPasteError')),
        type: 'danger',
        time: 15000
      });
    }
  }

  removeField() {
    this.orderService.removeLineItem(this.item.id);
  }

  ngOnInit() {
    if (this.item.sorting === this.orderService.addedSorting) {
      this.addedState = 'added';
      this.orderService.addedSorting = -1;
    }

    this.item.set?.sort((a, b) => a.slot - b.slot);

    if (this.item.isField) {
      this.initSlots();
      this.initActiveFields();
    }

    if (this.item.isWall) {
      const opt = this.wallAmountOptions.find((opt) => +opt.value == this.item.amount);
      if (opt) {
        this.wallAmountSelected = opt;
      } else {
        this.wallAmountSelected = this.wallAmountOptions[1];
      }

      const wallSideOpt = this.sideOptions.find((opt) => this.item.custom?.includes(opt.value));
      if (wallSideOpt) {
        this.wallSelectedSide = wallSideOpt;
      } else {
        this.wallSelectedSide = this.sideOptions[0];
      }

      if (this.item.custom) {
        this.wallFlat = this.item.custom.includes('flat');
      }
    }

    if (this.item.isSocket && this.item.custom) {
      this.customSocketDivision = this.item.custom.split(', ').map((i) => +i);
      this.checkCustomSocketDivisionWarning();
    }

    if (this.item.isSocket) {
      const opt = this.socketHeightOptions.find((o) => +o.value == this.item.height);

      if (opt) {
        this.selectedSocketHeightOption = opt;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['item'] && !changes['item'].firstChange) {
      if (this.item.isField) {
        this.initActiveFields();
        this.initSlots();

        this.slotOptions[11] = this.productService.getSlot(11).pipe(
          map((s) => s.filter((slot) => slot.additionalDescription == '')),
          this.productToOptionsPipe(
            11,
            this.translate.instant('Configuration.Fields.FieldSelectDefaults.CartridgeHolder')
          )
        );

        this.slotOptions[12] = this.productService.getSlot(12).pipe(
          map((s) => s.sort((a, b) => a.sorting - b.sorting)),
          map((s) =>
            s.map((slot) => {
              if (slot.id == this.item.set.find((set) => set.slot == 12)?.productId) {
                this.selectedSlots[12] = this.productSlotToOption(
                  s.find((findS) => findS.productNumber == slot.productNumber.substring(0, 4))!
                );
              }
              return slot;
            })
          ),
          map((s) => s.filter((slot) => slot.additionalDescription == '')),
          this.productToOptionsPipe(
            12,
            this.translate.instant('Configuration.Fields.FieldSelectDefaults.SchemaCompartment'),
            false
          )
        );
      }

      if (this.item.isWall) {
        const wallSideOpt = this.sideOptions.find((opt) => this.item.custom?.includes(opt.value));
        if (wallSideOpt) this.wallSelectedSide = wallSideOpt;

        if (this.item.custom) {
          this.wallFlat = this.item.custom.includes('flat');
        }
      }

      if (this.item.isSocket && this.item.custom) {
        this.customSocketDivision = this.item.custom.split(', ').map((i) => +i);
        this.checkCustomSocketDivisionWarning();

        const opt = this.socketHeightOptions.find((o) => +o.value == this.item.height);

        if (opt) {
          this.selectedSocketHeightOption = opt;
        }
      }

      if (this.item.isSocket) {
        const opt = this.socketHeightOptions.find((o) => +o.value == this.item.height);

        if (opt) {
          this.selectedSocketHeightOption = opt;
        }
      }
    }
  }

  initLabels() {
    this.colorOptions = ['Black', 'White', 'Yellow', 'Red', 'Blue'].map((color) => ({
      value: color,
      description: '',
      meta: '',
      label: this.translate.instant(`Configuration.Label.Colors.${color}`)
    }));
    this.color = this.colorOptions.find((o) => o.value == this.color?.value)!;

    this.labelPositionOptions = ['Aperture', 'Door'].map((pos) => ({
      value: pos,
      description: '',
      meta: '',
      label: this.translate.instant(`Configuration.Label.Pos1.${pos}`)
    }));
    this.labelPosition = this.labelPositionOptions.find((o) => o.value == this.labelPosition?.value)!;

    this.labelPosition2Options = ['Left', 'Center', 'Right'].map((pos) => ({
      value: pos,
      description: '',
      meta: '',
      label: this.translate.instant(`Configuration.Label.Pos2.${pos}`)
    }));
    this.labelPosition2 = this.labelPosition2Options.find((o) => o.value == this.labelPosition2?.value)!;
  }

  initSides() {
    this.sideOptions = [
      {
        value: 'Left',
        description: '',
        meta: '',
        label: this.translate.instant('Configuration.Fields.Sides.Left')
      },
      {
        value: 'Right',
        description: '',
        meta: '',
        label: this.translate.instant('Configuration.Fields.Sides.Right')
      }
    ];
  }

  initMaterials() {
    this.materialOptions = [
      {
        value: '-1',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.TopClosed')
      },
      {
        value: '',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.TopOpen')
      },
      {
        value: 'Moosgummiklemmplatte IP40',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.MGKP_IP40')
      },
      {
        value: 'Kabeldurchführungsplatte IP54',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.KDFP_IP54')
      },
      {
        value: 'Kunststoffplatte IP40',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.KSP_IP40')
      },
      {
        value: 'Kunststoffplatte IP54',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.KSP_IP54')
      },
      {
        value: 'Aluminiumplatte IP40',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.AP_IP40')
      },
      {
        value: 'Aluminiumplatte IP54',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.AP_IP54')
      },
      { value: 'Bürsten', description: '', label: this.translate.instant('Configuration.Fields.Materials.Brushes') }
    ];

    this.materialOptionsFloor = [
      {
        value: '',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.None')
      },
      {
        value: 'Kunststoffplatte IP40',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.KSP_IP40')
      },
      {
        value: 'Moosgummiklemmplatte IP40',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.MGKP_IP40')
      },
      {
        value: 'Kabeldurchführungsplatte IP54',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.KDFP_IP54')
      },
      { value: 'Bürsten', description: '', label: this.translate.instant('Configuration.Fields.Materials.AP_IP54') },
      {
        value: 'Kunststoffplatte IP54',
        description: '',
        label: this.translate.instant('Configuration.Fields.Materials.KSP_IP54')
      }
    ];
  }

  initSlots() {
    // Prefetch all slots at once to reduce calls
    this.productService.getSlots([
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '20',
      '21',
      '22',
      '23',
      '25',
      '30'
    ]);

    this.slotOptions[4] = this.productService.getSlot(4).pipe(this.productToOptionsPipe(4));

    this.slotOptions[5] = this.productService.getSlot(5).pipe(
      map((s) => s.sort((a, b) => a.sorting - b.sorting)),
      map((s) => {
        if (this.item.width === 1000) return s;
        return s.filter((slot) =>
          this.item.width != undefined && this.item.width > 1000 ? this.isDoubleDoor(slot) : !this.isDoubleDoor(slot)
        );
      }),
      this.productToOptionsPipe(5, this.translate.instant('Configuration.Fields.FieldSelectDefaults.Door')),
      tap((slots) => (this.emptySlot5 = slots[0])),
      tap((slots) => (this.defaultSlot5 = slots.find((s) => s.meta == 'trv')))
    );

    this.slotOptions[6] = this.productService.getSlot(6).pipe(
      map((s) => s.sort((a, b) => a.sorting - b.sorting)),
      // Filter of old unused locks / not available anymore
      // https://projekte.netzreich.de/netzreich/ass/ass/-/issues/13
      map((s) => s.filter((slot) => !['sstt'].includes(slot.productNumber))),
      this.productToOptionsPipe(6, this.translate.instant('Configuration.Fields.FieldSelectDefaults.Lock')),
      tap((slots) => (this.emptySlot6 = slots[0])),
      tap((slots) => (this.defaultSlot6 = slots.find((s) => s.meta == 'ss46')))
    );

    this.slotOptions[8] = this.productService.getSlot(8).pipe(this.productToOptionsPipe(8, null));

    this.slotOptions[9] = this.productService.getSlot(9).pipe(this.productToOptionsPipe(9, null));

    this.slotOptions[10] = this.productService.getSlot(10).pipe(
      this.productToOptionsPipe(10, 'Klapptablar'),
      map((s) => s.filter((slot) => slot.meta == 'kt'))
    );

    this.slotOptions[11] = this.productService.getSlot(11).pipe(
      map((s) => s.filter((slot) => slot.additionalDescription == '')),
      this.productToOptionsPipe(11, this.translate.instant('Configuration.Fields.FieldSelectDefaults.CartridgeHolder'))
    );

    this.productService.getSlot(12).subscribe((prods) => {
      this.slot12All.next(prods);
    });
    this.slotOptions[12] = this.productService.getSlot(12).pipe(
      map((s) => s.sort((a, b) => a.sorting - b.sorting)),
      map((s) =>
        s.map((slot) => {
          if (slot.id == this.item.set.find((set) => set.slot == 12)?.productId) {
            this.selectedSlots[12] = this.productSlotToOption(
              s.find((findS) => findS.productNumber == slot.productNumber.substring(0, 4))!
            );
          }
          return slot;
        })
      ),
      map((s) => s.filter((slot) => slot.additionalDescription == '')),
      this.productToOptionsPipe(
        12,
        this.translate.instant('Configuration.Fields.FieldSelectDefaults.SchemaCompartment'),
        false
      )
    );

    this.slotOptions[14] = this.productService.getSlot(14).pipe(
      map((s) => s.sort((a, b) => a.sorting - b.sorting)),
      map((s) => s.filter((slot) => this.lights.includes(slot.productNumber))),
      map((s) =>
        s.filter((slot) => {
          if (slot.productNumber == 'slled900' && this.item?.width && this.item.width < 1100) {
            return false;
          }
          return true;
        })
      ),
      this.productToOptionsPipe(14, this.translate.instant('Configuration.Fields.FieldSelectDefaults.Lamp'))
    );

    this.slotOptions[15] = this.productService.getSlot(15).pipe(
      map((s) => s.sort((a, b) => a.productNumber.length - b.productNumber.length)),
      this.productToOptionsPipe(15, null)
    );

    this.slotOptions[13] = this.productService.getSlot(13).pipe(this.productToOptionsPipe(13, null));

    this.slotOptions[20] = this.productService.getSlot(20).pipe(
      map((floors) => {
        return floors
          .map((floor) => {
            floor.description = floor.description.split(',')[0];
            return floor;
          })
          .filter((floor) => floor.productNumber == 'uo' || floor.productNumber == 'bbd2');
      }),
      this.productToOptionsPipe(20, null)
    );

    this.slotOptions[22] = this.productService.getSlot(22).pipe(this.productToOptionsPipe(22, null));

    this.slotOptions[23] = this.productService.getSlot(23).pipe(
      map((s) => s.filter((s) => s.productNumber == 'amp')),
      this.productToOptionsPipe(23, null)
    );

    this.ralColor = this.productService.getSlot(30).pipe(
      this.productToOptionsPipe(30, null),
      map((s) => s.filter((slot) => slot.meta == 'rfs')[0])
    );

    this.ral7035 = this.productService.getSlot(30).pipe(
      this.productToOptionsPipe(30, null),
      map((s) => s.filter((slot) => slot.meta == '7035')[0])
    );
  }

  initActiveFields() {
    this.labelProduct = this.orderService.order$.value?.lineItems?.find(
      (l) => l.addedFrom && l.addedFrom.includes(this.item.id) && l.isLabel
    );

    if (this.labelProduct) {
      this.labelActive = true;
      const font = this.fontOptions.find(
        (opt) => opt.value == this.labelProduct?.additionalDescription?.split(',')[0]?.trim()
      );

      if (font) {
        this.font = font;
      }
      const fontSize = this.fontSizeOptions.find(
        (opt) => opt.value == (this.labelProduct?.additionalDescription?.split(',')[1]?.trim()?.split(' ')[0] ?? '')
      );
      if (fontSize) {
        this.fontSize = fontSize;
      }

      const desc =
        this.translate.currentLang == 'de'
          ? this.labelProduct?.additionalDescription
          : this.labelProduct?.additionalDescriptionFr;

      const color = this.colorOptions.find((opt) => opt.label == desc?.split(',')[4]?.trim());
      if (color) {
        this.color = color;
      }
      const pos1 = this.labelPositionOptions.find((opt) => opt.label == desc?.split(',')[2]?.trim());
      if (pos1) {
        this.labelPosition = pos1;
      }
      const pos2 = this.labelPosition2Options.find((opt) => opt.label == desc?.split(',')[3]?.trim());
      if (pos2) {
        this.labelPosition2 = pos2;
      }
    }

    const mat = this.item.set.find((s) => s.slot == 15)?.custom ?? '';
    const matOpt = this.materialOptions.find((m) => m.value == mat);
    if (matOpt) {
      this.slot15SelectedCustom = matOpt;
    } else {
      this.slot15SelectedCustom = this.materialOptions[0];
    }

    this.doubleDoorSelected =
      this.item.set.find(
        (l) => l.slot == 5 && ['dtasv', 'dtv', 'dtasvtl', 'dtasvtr', 'tglv', 'tgrv'].includes(l.productNumber)
      ) != undefined;

    const matFloor = this.item.set.find((s) => s.slot == 20)?.custom;
    const matFloorOpt = this.materialOptionsFloor.find((m) => m.value == matFloor);
    if (matFloorOpt) {
      this.slot20SelectedCustom = matFloorOpt;
    } else {
      this.slot20SelectedCustom = this.materialOptionsFloor[0];
    }

    const optSH = this.socketHeightOptions.find((o) => +o.value == this.item.height);
    if (optSH) {
      this.selectedSocketHeightOption = optSH;
    }

    this.selectedProtection = this.protectionLevelOptions.find((p) => p.value == this.item.ipProtectionType) as Option;

    const slot11 = this.item.set.find((s) => s.slot == 11);
    this.slot11amount = slot11 ? slot11.amount : 1;

    const slot12 = this.item.set.find((s) => s.slot == 12);
    this.slot12amount = slot12 ? slot12.amount : 1;

    this.slot8active = this.item.set.find((s) => s.slot == 8) !== undefined;

    const slot9 = this.item.set.find((s) => s.slot == 9);
    this.slot9amount = slot9 ? slot9.amount : 0;
    this.slot9amount2 = slot9 ? slot9.amount2 : 0;
    this.slot9amount3 = slot9 ? slot9.amount3 : 0;

    this.slot15active = this.item.set.find((s) => s.slot == 15) !== undefined;
    this.slot20active = this.item.set.find((s) => s.slot == 20) !== undefined;

    this.slot10active = this.item.set.find((s) => s.slot == 10) !== undefined;
    this.slot10SelectedSide =
      this.item.set.find((s) => s.slot == 10)?.side == 'Left' ? this.sideOptions[0] : this.sideOptions[1];

    this.slot11active = this.item.set.find((s) => s.slot == 11) !== undefined;
    this.slot11SelectedSide = slot11?.side == 'Left' ? this.sideOptions[0] : this.sideOptions[1];

    this.slot12active = this.item.set.find((s) => s.slot == 12) !== undefined;
    this.slot12SelectedSide = slot12?.side == 'Left' ? this.sideOptions[0] : this.sideOptions[1];

    const slot13 = this.item.set.find((s) => s.slot == 13);
    this.slot13amount = slot13 ? slot13.amount : 0;
    const opt = this.slot13Options.find((opt) => +opt.value == this.slot13amount);
    if (opt) this.slot13SelectedOption = opt;

    this.slot22active = this.item.set.find((s) => s.slot == 22) !== undefined;
    this.slot23active = this.item.set.find((s) => s.slot == 23) !== undefined;

    const selectedRal = this.item.set.find((s) => s.slot == 30)?.custom;
    const color = this.RAL_COLORS.find((c) => c.code == (selectedRal ? selectedRal : '7035'));
    if (color) this.selectedColor = color;
  }

  updateSlotRef(dto: updateSlot) {
    this.updateSlot(dto.slot, dto.opt, dto.checked, dto.side, dto.custom, dto.amount, dto.amount2, dto.amount3);
  }

  updateSlot(
    slot: string,
    opt: Option,
    checked = true,
    side: string | undefined = undefined,
    custom: string | undefined = undefined,
    amount: number = 1,
    amount2: number = 0,
    amount3: number = 0
  ) {
    if (slot == '9' && amount == 0 && amount2 == 0 && amount3 == 0) {
      this.orderService.removeLineItemSlot(this.item.id, '9');
      return;
    }

    if (slot == '12' && side) {
      const sideProductNumber = side === 'Right' ? 'tr' : 'tl';
      const sideProd = this.slot12All.value.find(
        (prod) => prod.productNumber.includes(sideProductNumber) && prod.productNumber.includes(opt.meta)
      );

      if (sideProd) {
        opt = this.productSlotToOption(sideProd);
      }
    }

    if (slot == '13' && amount == 0) {
      this.orderService.removeLineItemSlot(this.item.id, '13');
      return;
    }

    if (slot == '4') {
      if (opt.meta == 'ot' || opt.meta == 'otorw') {
        this.selectedSlots[5] = this.emptySlot5;
        this.selectedSlots[6] = this.emptySlot6;
        if (this.item.set.find((s) => s.slot == 8)) this.orderService.removeLineItemSlot(this.item.id, '8');
      }
      // if (opt.meta == 'orw' || opt.meta == 'otorw') {
      // }
    }

    if (opt.value == '' || !checked) {
      if (this.item.set.find((s) => s.slot == +slot)) {
        this.orderService.removeLineItemSlot(this.item.id, slot);
      } else {
        console.log('Slot not in item');
      }
    } else {
      this.orderService.addLineItemSlot(this.item.id, slot, {
        productId: opt.value,
        side: side as 'Left' | 'Right',
        custom,
        amount,
        amount2,
        amount3
      });
    }

    if (slot == '4' && (opt.meta == undefined || opt.meta == 'orw')) {
      // if (this.defaultSlot5 != undefined) {
      //   this.orderService.addLineItemSlot(this.item.id, '5', {
      //     productId: this.defaultSlot5.value,
      //   });
      // }
      // if (this.defaultSlot6 != undefined) {
      //   this.orderService.addLineItemSlot(this.item.id, '6', {
      //     productId: this.defaultSlot6.value,
      //   });
      // }
    }
  }

  productsToSelectedPipe(slotItems: Product[]) {
    for (const item of slotItems) {
      if (item.slot == undefined) continue;
      if (item.id == this.item.set.find((s) => s.slot == item.slot)?.productId) {
        this.selectedSlots[item.slot] = this.productSlotToOption(item);
      }

      // Slot 5 Default values
      if (item.productNumber == 'trv') this.defaultSlot5 = this.productSlotToOption(item);
      this.emptySlot5 = {
        value: '',
        description: '',
        label: this.translate.instant('Configuration.Fields.FieldSelectDefaults.Door')
      };
    }
  }

  productToOptionsPipe(slotNr: number, startingElem: string | null = '-', findSelected = true) {
    return map((s: Product[]) => {
      let foundSelected = false;
      const res = s.map((slot) => {
        const opt = this.productSlotToOption(slot);
        if (slot.id == this.item.set.find((s) => s.slot == slotNr)?.productId) {
          if (findSelected) {
            this.selectedSlots[slotNr] = opt;
          }
          foundSelected = true;
        }
        return opt;
      });

      if (startingElem != null)
        res.unshift({
          value: '',
          description: '',
          label: startingElem
        });
      if (findSelected && !foundSelected && res.length > 0) {
        this.selectedSlots[slotNr] = res[0];
      }
      return res;
    });
  }

  productSlotToOption(prod: Product): Option {
    const description =
      this.translate.currentLang === undefined || this.translate.currentLang !== 'fr'
        ? prod.description
        : prod.descriptionFr;
    const additionalDescription =
      this.translate.currentLang === undefined || this.translate.currentLang !== 'fr'
        ? prod.additionalDescription
        : prod.additionalDescriptionFr;

    let description1 = description.trim().replace(/-$/g, '');
    const description2 = additionalDescription;

    if (
      !description.endsWith(' ') &&
      !description.endsWith('-') &&
      !description.endsWith('- ') &&
      !description2.startsWith(' ')
    ) {
      description1 = description1 + ' ';
    }

    return {
      value: prod.id,
      description: '',
      label: `${description1}${description2}`,
      meta: prod.productNumber
    };
  }

  updateRalColor(code: string, colorOpt: Option) {
    const color = this.RAL_COLORS.find((c) => c.code == code);

    if (color) {
      this.selectedColor = color;
      this.updateSlot('30', colorOpt, true, undefined, this.selectedColor.code);
    } else {
      this.ralError = `RAL-${code} konnte nicht gefunden werden`;
    }
  }

  updateWidth(event: string) {
    this.orderService.updateLineItem(this.item.id, { width: +event });
  }

  updateHeight(event: string) {
    this.orderService.updateLineItem(this.item.id, { height: +event });
  }

  updateDepth(event: string) {
    this.orderService.updateLineItem(this.item.id, { depth: +event });
  }

  updateAmount(event: string) {
    this.orderService.updateLineItem(this.item.id, { amount: +event });
  }

  updateWallCustom() {
    const custom = `${this.wallFlatValue} ${this.wallSelectedSideValue}`;
    this.updateCustom(custom);
  }

  get wallSelectedSideValue() {
    return this.wallAmountSelected.value == '1' ? this.wallSelectedSide.value : '';
  }

  get wallFlatValue() {
    return this.wallFlat ? 'flat ' : '';
  }

  updateCustom(event: string) {
    this.orderService.updateLineItem(this.item.id, { custom: event });
  }

  updateOverride(event: boolean) {
    this.orderService.updateLineItem(this.item.id, { override: event });
  }

  updateLabelActive() {
    if (!this.labelActive) {
      this.updateLabel();
    }
  }

  async updateProtectionLevel(event: string) {
    if (event == '54' && this.item.set.find((s) => s.slot == 8)) {
      await this.orderService.removeLineItemSlot(this.item.id, '8');
    }
    this.orderService.updateLineItem(this.item.id, { ipProtectionType: event });
  }

  isDoubleDoor(slot: Product) {
    return (
      slot.description.toLowerCase().includes('doppel') || slot.description.toLowerCase().includes('gleichschliessend')
    );
  }
}
