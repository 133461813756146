import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlComponent } from './al.component';
import { LanguageModule } from '../../../language.module';
import { AtomsModule } from '../../../atoms/atoms.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../../../pipes/pipes.module';
import { BaseComponent } from './slots/base/base.component';
import { DoorComponent } from './slots/door/door.component';
import { HolesComponent } from './slots/holes/holes.component';
import { KlapptablarComponent } from './slots/klapptablar/klapptablar.component';
import { CartridgeComponent } from './slots/cartridge/cartridge.component';
import { SchemaComponent } from './slots/schema/schema.component';
import { LampComponent } from './slots/lamp/lamp.component';
import { EntryTopComponent } from './slots/entry-top/entry-top.component';
import { EntryBotComponent } from './slots/entry-bot/entry-bot.component';
import { MountingComponent } from './slots/mounting/mounting.component';
import { RoofComponent } from './slots/roof/roof.component';
import { ColorComponent } from './slots/color/color.component';
import { ALAttachmentsComponent } from './slots/attachments/attachments.component';
import { LabelComponent } from './slots/label/label.component';

@NgModule({
  declarations: [
    ALAttachmentsComponent,
    AlComponent,
    BaseComponent,
    DoorComponent,
    HolesComponent,
    KlapptablarComponent,
    CartridgeComponent,
    SchemaComponent,
    LampComponent,
    EntryTopComponent,
    EntryBotComponent,
    MountingComponent,
    RoofComponent,
    ColorComponent,
    LabelComponent
  ],
  imports: [CommonModule, LanguageModule, AtomsModule, MatTooltipModule, PipesModule],
  exports: [AlComponent, ALAttachmentsComponent]
})
export class AlModule {}
